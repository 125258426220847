import type Vue from 'vue'
import { RouteConfig } from 'vue-router'

function generateRoutes (app: Vue) {
  const routes: RouteConfig[] = []

  routes.push({
    path: '/course/:slug',
    name: 'base-management.course.view',
    component: () => import(/* webpackChunkName: "runs-views" */'../views/CourseView.vue'),
    props: true
  })

  if (app.$permission.can(null, 'run-view')) {
    routes.push({
      path: '/runs',
      name: 'base-management.runs',
      component: () => import(/* webpackChunkName: "runs-views" */'../views/RunListView.vue')
    }, {
      path: '/courses',
      name: 'base-management.courses',
      component: () => import(/* webpackChunkName: "runs-views" */'../views/RunListView.vue')
    }, {
      path: '/run',
      alias: '/course',
      component: { render: (h: Function) => h('RouterView') },
      children: [{
        path: '/run/create/:view?',
        name: 'base-management.run.create',
        component: () => import(/* webpackChunkName: "runs-views" */'../views/RunEdit.vue'),
        props: true,
        meta: {
          breadcrumbs: {
            text: 'core.action.create',
            to: null
          }
        }
      }, {
        path: ':runId/:view?/:viewId?',
        name: 'base-management.run.edit',
        component: () => import(/* webpackChunkName: "runs-views" */'../views/RunEdit.vue'),
        props: true,
        meta: {
          breadcrumbs: {
            text: 'core.action.edit',
            to: null
          }
        }
      }, {
        path: ':runId/:view/:viewId/visualizations',
        name: 'base-management.run.visualizations.edit',
        component: () => import(/* webpackChunkName: "runs-views" */'../views/RunEdit.vue'),
        props: true,
        meta: {
          breadcrumbs: {
            text: 'core.action.edit',
            to: null
          }
        }
      }, {
        path: ':runId/content',
        component: { render: h => h('RouterView') },
        children: [{
          path: ':moduleId/preview',
          name: 'base-management.run.module.preview',
          component: () => import(/* webpackChunkName: "modules-views" */'../../modules/views/ModuleView.vue'),
          props: true,
          meta: {
            breadcrumbs: {
              text: 'core.action.preview',
              to: null
            }
          }
        }]
      }, {
        path: ':runId/content',
        component: { render: h => h('RouterView') },
        children: [{
          path: ':id/:view?',
          name: 'base-management.run.module.edit',
          component: () => import(/* webpackChunkName: "modules-views" */'../../modules/views/ModuleEdit.vue'),
          props: true,
          meta: {
            breadcrumbs: {
              text: 'core.action.edit',
              to: null
            }
          }
        }]
      }, {
        path: ':runIdentifier/evaluation',
        component: { render: h => h('RouterView') },
        children: [{
          path: ':evaluationIdentifier/present',
          name: 'participant-management.run.evaluation.present',
          component: () => import(/* webpackChunkName: "modules-views" */'../../../tracking-evaluation/views/TrackingVisualizationPresent.vue'),
          props: true,
          meta: {
            appBarComponent: null,
            breadcrumbs: {
              text: 'core.action.present',
              to: null
            }
          }
        }, {
          path: ':evaluationIdentifier/visualizations',
          name: 'participant-management.run.evaluation.visualizations',
          component: () => import(/* webpackChunkName: "modules-views" */'../../../tracking-evaluation/views/TrackingVisualization.vue'),
          props: true
        }, {
          path: ':evaluationIdentifier/visualization/:visualizationId',
          name: 'participant-management.run.evaluation.visualization.edit',
          component: () => import(/* webpackChunkName: "modules-views" */'../../../tracking-evaluation/views/VisualizationEdit.vue'),
          props: true
        }, {
          path: ':evaluationIdentifier/ranking/:visualizationId',
          name: 'participant-management.run.evaluation.ranking.edit',
          component: () => import(/* webpackChunkName: "modules-views" */'../../../tracking-evaluation/views/RankingEdit.vue'),
          props: true
        }]
      }]
    })
  } else if (app.$permission.can(null, 'dashboard-view')) {
    routes.push({
      path: '/courses',
      name: 'participant.courses',
      component: () => import(/* webpackChunkName: "runs-views" */'../views/ParticipantCourseList.vue') as any,
      meta: {
        breadcrumbs: {
          to: '/courses',
          text: 'participants.global.courses'
        }
      }
    })
    routes.push({
      path: '/events',
      name: 'participant.events',
      component: () => import(/* webpackChunkName: "runs-views" */'../views/ParticipantEventList.vue'),
      meta: {
        breadcrumbs: {
          to: '/events',
          text: 'participants.global.events'
        }
      }
    })
  }
  return routes
}

const eagerRoutes = [{
  path: '/event/:slug',
  alias: ['/survey/:slug', '/assessment/:slug', '/workshop/:slug', '/training/:slug', '/presentation/:slug'],
  name: 'base-management.event.view',
  component: () => import(/* webpackChunkName: "runs-eager-views" */'../views/EventView.vue'),
  meta: {
    guest: true,
    noCookieConsent: true
  },
  props: true
}, {
  path: '/qr/:slug',
  name: 'base-management.run-qr.view',
  component: () => import(/* webpackChunkName: "runs-eager-views" */'../views/RunQrView.vue'),
  props: true,
  meta: {
    guest: true,
    appBarComponent: null
  }
}] as RouteConfig[]

export {
  generateRoutes,
  eagerRoutes
}
